<template>
  <div class="switch-organisme">
    <div
      v-if="selectIsLoading"
      class="loader flex-vcenter"
    >
      <InfiniteLoader class="loader" />
    </div>
    <SelectClassic
      v-else-if="allOrganismes.length > 1"
      v-model="selectedOrg"
      :label="label"
      :inline="inline"
      :status="required ? 'required' : null"
      :internal-search="false"
      :placeholder="$t('general.placeholder-selectionner')"
      :options="organismes"
      :options-are-loading="optionsAreLoading"
      select-on-enter
      @search="fetchOrganismes"
      @close="switchOrganisme"
    >
      <template #label-icon>
        <UilBuilding size="16" />
      </template>
      <template #after-option-item="{ option, }">
        <!--
          @slot Emplacement pour un élément à droite de l'option.
          @binding {Object} option Informations de l'élément du dropdown.
        -->
        <slot
          name="after-option-item"
          :option="option"
        />
      </template>
    </SelectClassic>
  </div>
</template>

<script>
import { SelectClassic, InfiniteLoader } from "@lde/core_lde_vue";

import { UilBuilding } from "@iconscout/vue-unicons";

import Api from "@/modules/axios";
import config from "@/config";

import { mapGetters } from "vuex";

/**
 * Affiche un select permettant de changer l'organisme courant. Applique la modification à la sélection contrairement
 * au composant SelectOrganismeInput.
 */
export default {
  name: "SwitchOrganisme",
  components: {
    SelectClassic,
    InfiniteLoader,
    UilBuilding,
  },
  props: {
    /**
     * Texte affiché dans le select (en haut).
     */
    label: {
      type: String,
      default: "",
    },
    /**
     * Affiche l'astérisque signant l'obligation de remplir ce champ.
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * Affiche le composant en une ligne.
     */
    inline: {
      type: Boolean,
      default: false,
    },
    /**
     * Cache le tag dans la zone de l'input.
     */
    hideInputTag: {
      type: Boolean,
      defaut: false,
    },
  },
  data() {
    return {
      selectIsLoading: false,
      optionsAreLoading: false,
      allOrganismes: [],
      organismes: [],
      select: {},
    };
  },
  computed: {
    ...mapGetters(["organismeActuel"]),
    /**
     * Formattage de l'objet du store pour correspondre au format attendu par SelectClassic
     */
    selectedOrg: {
      get() {
        if (!this.organismeActuel) {
          return null;
        }
        return {
          label: this.organismeActuel.nom_complet,
          value: this.organismeActuel.nom_complet,
          id: this.organismeActuel.id,
          type: this.organismeActuel.type,
        };
      },
      set(org) {
        this.select = org;
      },
    },
  },
  mounted() {
    this.selectIsLoading = true;
    this.fetchOrganismes().then(() => {
      this.selectIsLoading = false;
    });
  },
  methods: {
    /**
     * Récupère les organismes accessibles.
     * @param {String} searchText Texte tapé dans l'input.
     * @returns {Promise} Organismes accessibles.
     */
    fetchOrganismes(searchText = "") {
      this.optionsAreLoading = true;
      return this.$store.dispatch("fetchOrganismes", searchText)
        .then((res) => {
          this.organismes = res.data.results.map(
            (org) => ({
              label: org.nom_complet,
              value: org.nom_complet,
              id: org.id,
              type: org.type,
            }),
          );
          // Permet de conserver la liste de tous les organismes pour savoir si l'utiliteur peut
          // changer d'organisme depuis le select.
          if (searchText === "") {
            this.allOrganismes = this.organismes;
          }
          this.optionsAreLoading = false;
        });
    },
    /**
     * Change d'organisme.
     */
    switchOrganisme() {
      if (this.select.id && this.select.id !== this.organismeActuel?.id) {
        // voir frontend/src/components/modals/ModalVerifyAccount.vue
        localStorage.removeItem("sessionFondsPropresAccept");

        Api().post("/switch/", { organisme: this.select.id, chosen_org: true })
          .then(({ data }) => {
            this.$store.commit("setOrganismeActuel", data);
            // Redirection vers l'accueil sans router.push pour faire un reload pour redéclencher le identify et
            // éviter d'éventuels soucis
            window.location = config.backUrl;
          });
      }
    },
  },
};
</script>

<style lang="scss">
@use "~@lde/core_lde_vue/dist/assets/styles/_variables.scss" as var;

.switch-organisme {
  .loader { height: 100%; }

  .tag {
    margin-top: 0;
    margin-bottom: 0;
    white-space: nowrap;
  }
}
</style>
