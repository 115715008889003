// La disponibilité a été complètement revue 28/06/2023.
// Les régles sont disponibles ici : https://app.clickup.com/t/860r784y2
// Etre "disponible à la vente" ne correspond pas à "code_disponibilite === 1"
// car une offre peut être vendu ayant d'autres codes de disponibilté.
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["user"]),
    computedIsProductAvailable() {
      // this.product doit venir du composant
      return this.product ? this.isProductAvailable(this.product) : false;
    },
    couleurDispo() {
      // this.product doit venir du composant
      return this.product ? this.couleurSelonDisponibilite(this.product) : "";
    },
    libelleDispo() {
      // this.product doit venir du composant
      return this.product ? this.libelleSelonDisponibilite(this.product) : "";
    },
    badgeDispoInfo() {
      const badgeInfo = {};
      // this.product doit venir du composant
      if (this.product?.produit_remplacement) {
        badgeInfo.variant = "remplace";
      } else if (!this.computedIsProductAvailable) {
        badgeInfo.title = this.libelleDispo;
        badgeInfo.variant = "error";
      }
      return badgeInfo;
    },
  },
  data() {
    return {
      acceptedCodes: [
        1, // Disponible
        2, // A paraitre
        3, // En cours de réimpression
        4, // Non disponible provisoirement
        5, // Changement de distributeur
        8, // A reparaitre
        97, // Disponible jusqu'à épuisement des stocks
      ],
    };
  },
  methods: {
    /**
     * Indique si l'état de disponibilité global du produit est disponible ou non.
     * @param {Object} product Données du produit.
     * @returns {Boolean} true si le produit n'a pas été remplacé et si son offre par défaut est disponible à la vente.
     */
    isProductAvailable(product) {
      return this.acceptedCodes.includes(product?.offre_defaut?.code_disponibilite);
    },
    /**
     * Indique si l'offre donnée peut être mise en liste, devis, commande.
     * On traite les remplacements pour les licences et les articles papier dans certains cas.
     * @param {Object} offre Données de l'offre.
     * @returns {Boolean} true si l'offre est disponible à la vente et si le produit n'a pas été remplacé.
     */
    isOfferAvailable(offre) {
      return this.acceptedCodes.includes(offre?.code_disponibilite);
    },
    /**
     * Indique la couleur du texte et de la pastille selon la disponibilité du produit donné.
     * @param {Object} product Données du produit.
     * @returns {String} Couleur de l'état de disponibilité associée.
     */
    couleurSelonDisponibilite(product) {
      if (product?.offre_defaut) {
        const { code_disponibilite: code } = product.offre_defaut;

        // Disponible
        if ([1, 97].includes(code)) {
          return "green";
        }

        // Un produit ayant une disponibilité "orange" peut être mis en liste.
        if ([2, 3, 4, 5, 8].includes(code)) {
          return "orange";
        }
      }

      // Les codes 6, 7, 9 et 98 peuvent éxister mais doivent retourner "red".
      // Si pas d'offre par défaut ou si le code ne rentre pas dans les règles, retourner "red" aussi.
      return "red";
    },
    /**
     * Indique le texte de disponibilité pour un produit donnée.
     * @param {Object} product Données du produit.
     * @returns {String} Libellé associé à l'état de disponibilité.
     */
    libelleSelonDisponibilite(product) {
      let label = null;
      if (product?.offre_defaut) {
        switch (product?.offre_defaut.code_disponibilite) {
          case 1:
            label = this.$t("produit.disponible");
            break;
          case 2:
            // On affiche la date seulement si elle est se situe dans les 5 années à venir
            if (
              this.product.date_publication && this.$moment(this.product.date_publication).isBetween(
                this.$moment(),
                this.$moment().add(5, "years"),
                "days",
                "(]", // "(" et ")" = exclusion, "[" et "]" = inclusion
              )
            ) {
              label = this.$t("produit.a-paraitre-le-x", {
                date: this.$moment(this.product.date_publication).format(this.$t("global.format-date")),
              });
            } else {
              label = this.$t("produit.a-paraitre");
            }
            break;
          case 3:
            label = this.$t("produit.en-cours-de-reimpression");
            break;
          case 4:
            label = this.$t("produit.non-disponible-provisoirement");
            break;
          case 5:
            label = this.$t("produit.changement-de-distributeur");
            break;
          case 6:
            label = this.$t("produit.arret-definitif-de-commercialisation");
            break;
          case 7:
            label = this.$t("produit.manque-sans-date");
            break;
          case 8:
            label = this.$t("produit.a-reparaitre");
            break;
          case 9:
            label = this.$t("produit.non-disponible-a-long-terme");
            break;
          // On ne traite pas actuellement le code 13 mais je laisse ici au cas où pour l'avenir.
          // case 13:
          //   label = this.$t("produit.arret-definitif-de-commercialisation");
          //   break;
          case 97:
            label = this.$t("produit.disponible-jusqu-a-epuisement-des-stocks");
            break;
          case 98:
            label = this.$t("produit.retire-du-catalogue-editeur");
            break;
          default:
            label = this.$t("produit.indisponible");
        }
      } else if (!product?.produit_remplacement) {
        label = this.$t("produit.arret-definitif-de-commercialisation");
      } else {
        label = this.$t("produit.indisponible");
      }
      return label;
    },
  },
};
