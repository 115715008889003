<template>
  <InfiniteLoader v-if="isLoading" />
  <section
    v-else
    id="multiple_accounts"
  >
    <div
      v-if="organismes.length"
      class="grid-zone"
    >
      <Toast
        :title="$t('verif-compte.selection-d-un-etablissement')"
        :content="$t('verif-compte.vous-avez-acces-espaces-plusieurs-etbs')"
        variant="warning"
        show-icon
        hide-close-btn
      />
      <div class="multiple-accounts-content">
        <h1 class="s4 light">
          {{ $t('general.pour-continuer') }}
        </h1>
        <p class="text-regular bold">
          {{ $t("verif-compte.choisissez-contexte-travail") }}
        </p>
        <SwitchOrganisme
          v-if="organismes.length > 4"
          :label="$t('general.etablissement-actif-pour-session')"
          required
        >
          <template #after-option-item="{ option: org, }">
            <Tag
              v-if="org.type"
              :tag-name="org.type"
            />
          </template>
        </SwitchOrganisme>
        <ul v-else>
          <li
            v-for="org in organismes"
            :key="org.id"
          >
            <button
              class="options-list-item-btn"
              @click="switchOrg(org)"
            >
              <span>{{ org.label }}</span>
              <Tag
                v-if="org.type"
                :tag-name="org.type"
              />
            </button>
          </li>
        </ul>
      </div>
      <figure>
        <img
          :src="require('@lde/core_lde_vue/dist/assets/media/illus/illus_online_courses.svg')"
          :alt="$t('404.illutration-fleche-recherche')"
        />
      </figure>
    </div>
    <div
      v-else
      class="grid-zone"
    >
      <Toast
        :title="$t('info.une-erreur-s-est-produite')"
        :content="$t('no-account.profil-pas-rattache-ets')"
        variant="warning"
        show-icon
        hide-close-btn
      />
      <div
        id="no_account"
        class="multiple-accounts-content"
      >
        <h1 class="s4 light">
          {{ $t('general.pour-continuer') }}
        </h1>
        <div class="text-regular">
          <p>{{ $t("no-account.collectivites-imposent-passer-par-ent") }}</p>
          <p>
            {{ $t("no-account.contactez-equipe-commerciale") }}{{ "\xa0" }}:
            <a :href="`mailto:${mailLde}`">
              {{ mailLde }}
            </a>
          </p>
        </div>
        <div>{{ $t("general.identifiant") }} : {{ user.username }}</div>
      </div>
      <figure>
        <img
          :src="require('@lde/core_lde_vue/dist/assets/media/illus/illus_forbidden.svg')"
          :alt="$t('404.illustration-page-erreur')"
        />
      </figure>
    </div>
  </section>
</template>

<script>
import { Toast, Tag, InfiniteLoader } from "@lde/core_lde_vue";

import SwitchOrganisme from "@/components/forms/SwitchOrganisme.vue";

import Api from "@/modules/axios";
import { mapGetters } from "vuex";
import config from "@/config";

/**
 * S'affiche à la connexion si un contact possède plusieurs comptes afin de choisir un organisme courant.
 */
export default {
  name: "MultipleAccounts",
  components: {
    InfiniteLoader,
    Toast,
    Tag,
    SwitchOrganisme,
  },
  data() {
    return {
      selectedOrganisme: {},
      organismes: [],
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters(["user"]),
    mailLde() {
      return config.mails.lde;
    },
  },
  mounted() {
    return this.fetchOrganismes();
  },
  methods: {
    /**
     * Change d'organisme.
     * @param organisme Organisme à utiliser (devient donc l'organisme actuel).
     */
    switchOrg(organisme) {
      if (organisme.id) {
        Api().post("/switch/", { organisme: organisme.id, chosen_org: true })
          .then(({ data }) => {
            this.$store.commit("setOrganismeActuel", data);
            // Reload pour redéclencher le identify et éviter d'éventuels soucis
            window.location.reload();
          });
      }
    },
    /**
     * Récupère les organismes accessibles.
     */
    fetchOrganismes() {
      this.isLoading = true;
      // Cherche uniquement 5 org car si > 4 on affiche un select qui refait une requête.
      this.$store.dispatch("fetchOrganismes", "&page_size=5")
        .then((res) => {
          this.organismes = res.data.results.map(
            (org) => ({
              label: org.nom_complet,
              value: org.nom_complet,
              id: org.id,
              type: org.type,
            }),
          );
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/layout/multiple_accounts.scss";
</style>
