<template>
  <Modal
    id="modal_listes_creation"
    element-to-focus="input"
    @change="!$event && hide()"
  >
    <template #title>
      {{ $t('liste.creer-nouvelle-liste') }}
    </template>
    <template #content>
      <InputClassic
        v-model="listName"
        :label="$t('liste.indiquer-nom-liste')"
        :placeholder="$t('liste.exemple-creation')"
        :status="inputStatus"
        @change="inputStatus = 'required'"
        @keyup.enter.native="createList()"
      >
        <template #helper-icon>
          <UilInfoCircle size="16" />
        </template>
      </InputClassic>
    </template>
    <template #footer>
      <button
        class="button text-medium underline"
        @click="hide()"
      >
        {{ $t("action.annuler") }}
      </button>
      <ButtonClassic
        v-if="$route.name.includes('listes_devis')"
        variant="ghost"
        :label="$t('action.faire-saisie-rapide')"
        color="primary"
        icon="right"
        :disabled="disabledQuickListButton || listName === '' || isImpersonating"
        @click="openQuickListModal()"
      >
        <template #right-icon>
          <UilFileBookmarkAlt />
        </template>
      </ButtonClassic>
      <ButtonClassic
        variant="solid"
        :label="$t('liste.creer-liste')"
        color="primary"
        icon="right"
        :disabled="isImpersonating || listName === '' || isCreatingList"
        @click="createList()"
      >
        <template #right-icon>
          <UilBookmark />
        </template>
      </ButtonClassic>
    </template>
  </Modal>
</template>

<script>
import { ButtonClassic, InputClassic, Modal } from "@lde/core_lde_vue";
import mixinToListe from "@/mixins/mixinToListe";

import { UilBookmark, UilFileBookmarkAlt, UilInfoCircle } from "@iconscout/vue-unicons";

import Api from "@/modules/axios";

import { mapGetters } from "vuex";

/**
 * Modale affichée lors de la création d'une liste.
 */
export default {
  name: "ModalListesCreation",
  components: {
    Modal,
    ButtonClassic,
    InputClassic,
    UilBookmark,
    UilFileBookmarkAlt,
    UilInfoCircle,
  },
  mixins: [mixinToListe],
  emits: [
    /**
     * Déclenché au au clic sur le bouton de saisie rapide.
     * @param {String} - Nom de la liste.
     */
    "open-quick-list",
  ],
  data() {
    return {
      listName: "",
      inputStatus: "required",
      isCreatingList: false,
      disabledQuickListButton: false,
    };
  },
  computed: {
    ...mapGetters(["organismeActuel", "selectedLines", "panierToListResolver"]),
  },
  methods: {
    /**
     * Créer une liste avec le libellé de l'input et la met à jour avec les lignes sélectionnées s'il y en a.
     */
    createList() {
      if (this.listName) {
        if (this.panierToListResolver) {
          this.panierToListResolver(this.listName);
          this.listName = "";
          return;
        }

        this.isCreatingList = true;
        Api().post("/liste/", { libelle: this.listName })
          .then(({ data }) => {
            this.$toast.success({ title: this.$t("liste.liste-x-bien-creee", { libelle: data.libelle }) });

            if (this.selectedLines.length) {
              if (this.$route.name.includes("commandes_factures_commandes")) {
                this.addOrderLinesToList({ liste: data, commandes: this.selectedLines });
              } else {
                this.addOffersToList({ liste: data, lignes: this.selectedLines });
              }
            }
            this.isCreatingList = false;
            this.hide();
          })
          .catch((err) => {
            this.inputStatus = "error";
            this.$toast.error({ title: err.response?.data || this.$t("info.une-erreur-est-survenue") });
          });
      }
    },
    /**
     * Ferme la modale et remet les valeurs par défaut.
     */
    hide() {
      this.listName = "";
      this.isCreatingList = false;
      this.$modal.hide("modal_listes_creation");
      this.$store.commit("setSelectedLines", []);
    },
    /**
     * Affiche la modale de saisie rapide en gardant le nom de la liste.
     */
    openQuickListModal() {
      this.disabledQuickListButton = true;
      Api().get(`/liste/?organisme=${this.organismeActuel.id}&date_transfert__isnull=true`)
        .then(({ data: { results } }) => {
          if (results.some((liste) => liste.libelle === this.listName)) {
            this.$toast.error({ title: this.$t("liste.une-liste-du-meme-nom-existe-deja") });
          } else {
            this.$emit("open-quick-list", this.listName);
            this.hide();
            this.$modal.show("modal_listes_saisie_rapide");
          }
        })
        .catch((err) => {
          this.$toast.error({
            title: err.response?.data?.detail
              || (Array.isArray(err.response?.data) && err.response?.data[0])
              || this.$t("info.une-erreur-est-survenue"),
          });
        })
        .finally(() => {
          this.disabledQuickListButton = false;
        });
    },
  },
};
</script>

<style lang="scss">
@use "~@lde/core_lde_vue/dist/assets/styles/_variables.scss" as var;

#modal_listes_creation {
  width: 60rem;

  .modal-footer {
    justify-content: space-between;
    .button { color: var.$blue-light; }
  }
}
</style>
