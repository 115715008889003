<template>
  <div
    ref="search_suggestion"
    class="search-suggestions"
    :class="{'in-catalog-suggestions': showCatalogSuggestions, }"
  >
    <div class="content">
      <div class="flex-vcenter">
        <h2 class="options-list-container-title">
          {{ $t('recherche.suggestions-de-recherche') }}
        </h2>
        <template v-if="showParamButton">
          <ButtonClassic
            v-show="!showCatalogSuggestions"
            class="suggestion-preferences-button"
            variant="special"
            icon="left"
            size="small"
            color="primary"
            @click="showCatalogSuggestions = true; preferencesShown = true"
          >
            <template #left-icon>
              <UilSetting v-show="!preferencesShown" />
            </template>
          </ButtonClassic>
        </template>
      </div>
      <ul
        v-if="searchSuggestions && searchSuggestions.length"
        class="text-medium"
        @keyup.esc="$emit('close')"
      >
        <li
          v-for="(suggestion, index) in searchSuggestions"
          :key="`options-list-item-${index}`"
          class="options-list-item"
        >
          <ul class="second-lvl">
            <li
              v-for="(option, optionIndex) in suggestion.options"
              :key="'options-list-item-btn' + optionIndex"
              class="flex-vcenter"
            >
              <button
                type="button"
                class="options-list-item-btn"
                @click="$emit('select', ucFirst(option.titre))"
                v-html="highlightText(ucFirst(option.titre))"
              />
              <p
                v-if="optionIndex === 0"
                class="options-list-item-meta text-right italic"
              >
                {{ suggestion.nom_matiere }}
              </p>
            </li>
          </ul>
        </li>
      </ul>
      <span
        v-else
        class="help-btn text-medium"
      >
        {{ $t('recherche.aucune-suggestion-pour-cette-recherche') }}
      </span>
    </div>
    <div
      v-show="showCatalogSuggestions"
      class="content"
      :class="{'preferences': preferencesShown, }"
    >
      <div class="flex-vcenter">
        <h2
          v-if="!preferencesShown"
          class="options-list-container-title"
        >
          <template v-if="selected.value === 'defaut'">
            {{ $t('recherche.suggestions-produits-de-nos-catalogues') }}
          </template>
          <template v-if="selected.value === 'devis'">
            {{ $t('recherche.suggestions-produits-depuis-vos-devis') }}
          </template>
          <template v-if="selected.value === 'commandes'">
            {{ $t('recherche.suggestions-produits-depuis-vos-commandes') }}
          </template>
        </h2>
        <h2
          v-else
          class="preferences-title bold text-regular"
        >
          {{ $t('recherche.preferences-dinterface') }}
        </h2>
        <ButtonClassic
          class="suggestion-preferences-button"
          variant="special"
          icon="left"
          size="small"
          color="primary"
          @click="togglePreferences()"
        >
          <template #left-icon>
            <UilSetting v-show="!preferencesShown" />
            <UilTimes v-show="preferencesShown" />
          </template>
        </ButtonClassic>
      </div>
      <ul
        v-if="!preferencesShown"
        class="text-medium"
      >
        <span
          v-if="!inCatalog.length"
          class="help-btn text-medium"
        >
          <template v-if="selected.value === 'devis'">
            {{ $t('recherche.aucun-resultat-dans-vos-devis') }}
          </template>
          <template v-else-if="selected.value === 'commandes'">
            {{ $t('recherche.aucun-resultat-dans-vos-commandes') }}
          </template>
          <template v-else>
            {{ $t('recherche.aucun-resultat') }}
          </template>
        </span>
        <template v-else>
          <li
            v-for="(product, index) in inCatalog"
            :key="'options-list-item-' + index"
            class="options-product"
          >
            <button
              class="button flex-vcenter"
              @click="
                $router.push({ name: 'catalogues_ressources_numeriques_item', params: { id: product.id, }, });
                $emit('close')
              "
            >
              <figure class="image">
                <img
                  :src="product.url_couverture
                    || require(`@lde/core_lde_vue/dist/assets/media/missing_img/no_img_${noImgName(product)}.svg`)"
                  :alt="$t('general.alt-image-produit')"
                  @error="product.url_couverture = null"
                />
              </figure>
              <div class="product-description text-left">
                <p class="in-catalog-title text-medium">
                  {{ product.libelle }}
                </p>
                <span class="in-catalog-subtitle text-small">{{ product.editeur }}</span>
              </div>
            </button>
          </li>
        </template>
      </ul>
      <div
        v-else
        class="preferences-container"
      >
        <div>
          <ButtonToggle
            v-model="isSuggestionOpen"
            label="Afficher des suggestions issues de ..."
            type="switch"
            name="suggestion"
          />
          <SelectClassic
            v-model="selected"
            label="Sélectionnez l’application interrogée"
            :options="filtresOptions"
            :internal-search="false"
            :search-bar="false"
            :disabled="!isSuggestionOpen"
            @select="changeFilter($event)"
          />
        </div>
        <p class="text-small in-catalog-subtitle italic">
          {{ $t('recherche.en-mesure-de-suggerer-des-resultats') }}
          <br />
          {{ $t('recherche.application-de-cristal-commandes-les-listes-etc') }}
        </p>
        <!-- <p class="text-small in-catalog-subtitle italic">
          {{ $t('recherche.un-peu-plus-de-temps-mais-elles-ralentiront') }}
          </p> -->
        <!-- <button
          class="button link underline text-medium"
          @click="$router.push({ name: 'compte', }); $emit('close')"
          >
          {{ $t('recherche.toutes-les-preferences-interfaces') }}
          </button> -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  ucFirst,
  ButtonClassic,
  ButtonToggle,
  SelectClassic,
} from "@lde/core_lde_vue";

import { UilSetting, UilTimes } from "@iconscout/vue-unicons";
import { mapGetters } from "vuex";

/**
 * Affiche les suggestions de recherche depuis la barre de recherche principale.
 */
export default {
  name: "SearchSuggestions",
  components: {
    ButtonClassic,
    ButtonToggle,
    SelectClassic,
    UilSetting,
    UilTimes,
  },
  props: {
    /**
     * Suggestions de recherche.
     */
    searchSuggestions: {
      type: Array,
      default: () => [],
    },
    /**
     * Produits dans nos catalogues.
     */
    inCatalog: {
      type: Array,
      default: () => [],
    },
    /**
     * Texte à mettre en évidence.
     */
    textToHighlight: {
      type: String,
      default: "",
    },
  },
  emits: [
    /**
     * Déclenché au changement du select de scope (manuel/devis/commande).
     */
    "scope-change",
    /**
     * Déclenché à la fermeture (echap ou click sur lien à droite).
     */
    "close",
    /**
     * Déclenché au click sur une suggestion de gauche.
     * @param {Object} option Infos relatives à l'option.
     */
    "select",
  ],
  data() {
    return {
      preferencesShown: false,
      filtresOptions: [
        {
          label: this.$t("recherche.nos-suggestions"),
          value: "defaut",
        },
        {
          label: "Devis",
          value: "devis",
        },
        {
          label: "Commandes",
          value: "commandes",
        },
      ],
      selected: {
        label: this.$t("recherche.nos-suggestions"),
        value: "defaut",
      },
      showCatalogSuggestions: false,
      showParamButton: true,
    };
  },
  computed: {
    ...mapGetters(["suggestionFilter", "isSuggestionFilterOpen"]),
    isSuggestionOpen: {
      get() {
        return this.isSuggestionFilterOpen;
      },
      set(newVal) {
        this.$store.commit("setIsSuggestionFilterOpen", newVal);
      },
    },
  },
  mounted() {
    if (this.suggestionFilter) {
      this.selected = this.filtresOptions.find((filtre) => filtre.value === this.suggestionFilter)
                      || this.filtresOptions[0];
    } else {
      this.$store.commit("setSearchSuggestionFilter", this.filtresOptions[0].value);
    }
    this.showCatalogSuggestions = this.isSuggestionFilterOpen;
    this.$nextTick(() => {
      this.onResize();
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    /**
     * Lors du changement de la taille de la fenêtre.
     */
    onResize() {
      const { search_suggestion: searchSuggestion } = this.$refs;
      // 400px corresponds à la width de la colonne de droite
      if (searchSuggestion.clientWidth - 400 <= 400) {
        this.showParamButton = false;
        this.showCatalogSuggestions = false;
      } else {
        this.showParamButton = true;
        this.showCatalogSuggestions = this.isSuggestionFilterOpen;
      }
    },
    /**
     * Affiche ou cache les options de filtre pour les suggestions de droite.
     */
    togglePreferences() {
      this.preferencesShown = !this.preferencesShown;
      if (!this.isSuggestionOpen) {
        this.showCatalogSuggestions = false;
      }
    },
    /**
     * Définit le nom de l'image alternative quand le produit n'a pas d'image.
     * @param {Object} product Informations du produit.
     * @returns {String} Nom de l'image.
     */
    noImgName(product) {
      let type = "";
      // TODO: revoir quand on aura les livres papiers et les fournitures
      if (product.offre_defaut.manuel_numerique) {
        type = "numerique";
      } else if (product.offre_defaut.article) {
        type = "papier";
      } else if (product.offre_defaut.fourniture) {
        type = "fourniture";
      } else {
        type = "image";
      }

      return type;
    },
    /**
     * Au changement du select dans les filtres de la recherche.
     * @param {Object} filter Contient le filtre sélectionner.
     */
    changeFilter(filter) {
      this.$store.commit("setSearchSuggestionFilter", filter.value);
      this.$emit("scope-change");
    },
    /**
     * Permet d'ajouter un <span> dans un texte si on y trouve un texte donné.
     * @param {String} string Chaîne de caractères ciblée.
     * @returns {String} Texte englobé dans une balise <span>.
     */
    highlightText(string) {
      if (this.textToHighlight) {
        return string.replace(new RegExp(this.textToHighlight, "gi"),
          (match) => `<span class="bold">${match}</span>`);
      }

      return string;
    },
    ucFirst,
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/search/search_suggestions.scss";
</style>
