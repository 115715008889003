const isRecette = process.env.VUE_APP_ENVIRONMENT && process.env.VUE_APP_ENVIRONMENT === "recette";
const isGMBH = window.location.hostname.endsWith(".lde.de");

const API_URL = isGMBH ? process.env.VUE_APP_API_URL_GMBH : process.env.VUE_APP_API_URL;

// console.log("API_URL", API_URL);
const config = {
  loginUrl: `${API_URL}/login`,
  logoutUrl: `${API_URL}/logout`,
  backUrl: API_URL,
  api: {
    baseUrl: `${API_URL}/api`,
    debounceDelay: 750,
  },
  statutsCommande: {
    INITIAL: "", // statut_affiche = attente_selection
    TRANSMIS_DIRECTEUR: "TRANSMIS_DIRECTEUR", // statut_affiche = attente_validation
    VALIDE_DIRECTEUR: "VALIDE_DIRECTEUR", // statut_affiche = attente_soumission
    TRANSMIS: "TRANSMIS", // statut_affiche = attente_approbation
    VALIDE: "VALIDE", // statut_affiche = attente_commande
    REFUSE: "REFUSE", // statut_affiche = refus
  },
  supportedLocales: {
    fr: "Français",
    de: "Deutsch",
  },

  url: {
    extranet: isGMBH ? "https://extranet.lde.de/" : "https://extranet.LDE.fr/",
    extranetSearch: (
      isGMBH ? "https://extranet.lde.de/recherche?search=" : "https://extranet.LDE.fr/extranet/recherche?search="
    ),
    hotlineTicket: `https://${isRecette ? "recette-" : ""}hotline.lde.fr/scenarisation/viewer/`,
    lde: isGMBH ? "https://www.lde.de/" : "https://www.LDE.fr",
    hub: isGMBH ? "https://startseite-cristal-welt.lde.de" : "https://portail-univers-cristal.lde.fr",
    cristalWeb: isGMBH ? "https://login.lde.de/" : "https://cascristal.lde.fr/",
  },
  mails: {
    lde: isGMBH ? "service@LDE.de" : "accueil@LDE.fr",
  },
};

config.url.rgpd = `${config.url.hub}/rgpd`;
config.url.cgu = `${config.url.hub}/cgu/cristal-commandes`;
config.url.mentionsLegales = `${config.url.hub}/mentions-legales/cristal-commandes`;

export default config;
