<template>
  <div id="access_provider">
    <h1 class="s3">
      {{ $t('provider.acces-catalogue-lde') }}
    </h1>
    <div id="content">
      <div class="bloc">
        <p class="s5">
          {{ $t('provider.pas-identifiant-de-connexion') }}<br />
          {{ $t('provider.acces-site-consultation') }}<br />
          <em class="text-regular">{{ $t('provider.acces-a-notre-catalogue-en-lecture') }}</em>
        </p>

        <div class="zone-button">
          <ButtonClassic
            variant="solid"
            :label="$t('action.choisir-option')"
            icon="right"
            @click="chooseConsulting()"
          >
            <template #right-icon>
              <UilArrowRight />
            </template>
          </ButtonClassic>
        </div>
      </div>

      <hr />

      <div class="bloc">
        <p class="s5">
          {{ $t('provider.possedez-identifiant-lde') }}<br />
          {{ $t('provider.connexion-au-site-lde') }}<br />
          <em class="text-regular">{{ $t('provider.acces-catalogue-role-fourni') }}</em>
        </p>

        <div class="zone-button">
          <ButtonClassic
            variant="solid"
            :label="$t('action.choisir-option')"
            icon="right"
            @click="goToProviderLde()"
          >
            <template #right-icon>
              <UilArrowRight />
            </template>
          </ButtonClassic>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ButtonClassic } from "@lde/core_lde_vue";

import config from "@/config";

import { UilArrowRight } from "@iconscout/vue-unicons";

/**
 * Affiche une page destinée à choisir le moyen de se rendre sur le site.
 */
export default {
  name: "AccessProvider",
  components: {
    ButtonClassic,
    UilArrowRight,
  },
  methods: {
    /**
     * Redirige vers le CAS sur la page du provider.
     */
    goToProviderLde() {
      // Hack pour gérer le fait que certains ENT comme monlycee.net ne gèrent pas correctement la déconnexion
      const handle = window.open(config.logoutUrl);
      setTimeout(() => {
        handle.close();
        window.location = config.loginUrl;
      }, 1000);
    },
    /**
     * Permet de se connecter au site en consultation uniquement.
     */
    chooseConsulting() {
      this.$store.dispatch("login", { chosen_consulting: true });
    },
  },
};
</script>

<style lang="scss">
@use "~@lde/core_lde_vue/dist/assets/styles/_variables.scss" as var;

#access_provider {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var.$space-large;

  h1 { margin-bottom: var.$space-large; }

  #content {
    display: flex;
    height: 100%;
  }

  .bloc {
    flex: 1;
    &:first-child { margin-right: var.$space-small; }
    &:last-child { margin-left: var.$space-small; }
  }

  em {
    display: block;
    margin-top: var.$space-tiny;
    margin-bottom: var.$space-medium;
  }

  .zone-button {text-align: center; }

  hr {
    height: auto;
    margin: 0;
    border: 1px solid var.$orange;
  }
}
</style>
